import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LearnMoreLink from 'components/atoms/LearnMoreLink';
import React, { useRef, useState } from 'react';
import { confirmAccount } from 'util/idm';
import validate from 'validate.js';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 300,
    },
  },
};

const Form = (): JSX.Element => {
  const classes = useStyles();
  const emailRef = useRef()

  const paramSearchString = typeof window !== 'undefined' ? window.location.search : ''
  const urlParams = new URLSearchParams(paramSearchString)

  const [formState, setFormState] = React.useState<FormStateProps>({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const [showSuccess, setShowSuccess] = useState(false);
  const [errors, setErrors] = useState({});

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (formState.isValid) {

      confirmAccount(formState.values.email).then(response => {
        //NOTE Mutability is gross -JoeP
        formState.values.email = ""

        setFormState(formState => ({
          ...formState,
          touched: {
            ...formState.touched,
            ...formState.errors,
          },
        }));
        setShowSuccess(true)
      }).catch(error => {
        if (error.response.status == 422 || error.response.status == 401) {
          formState.errors = error.response.data.errors

          setFormState(formState => ({
            ...formState,
            touched: {
              ...formState.touched,
              ...formState.errors,
            },
          }));
        } else {
          console.log('Error', error.message);
          throw error;
        }
      });
    }

    setFormState(formState => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };

  const hasError = (field: string): boolean =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <form name="confirmation-form" method="post" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {showSuccess && <Typography paragraph>
              A new email has been sent. Don't forget to check your spam folder!
            </Typography>}
            <TextField
              ref={emailRef}
              placeholder="E-mail"
              label="E-mail *"
              variant="outlined"
              size="medium"
              name="email"
              fullWidth
              helperText={hasError('email') ? formState.errors.email[0] : null}
              error={hasError('email')}
              onChange={handleChange}
              type="email"
              value={formState.values.email || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <i>
              <Typography variant="subtitle2">
                Fields that are marked with * sign are required.
              </Typography>
            </i>
          </Grid>
          <Grid item xs={12}>
            <Button
              size="large"
              variant="contained"
              type="submit"
              color="primary"
              fullWidth
            >
              Resend
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              align="center"
            >
              Already have an account?{' '}
              <LearnMoreLink title="Sign in" href="/sign-in" />
            </Typography>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default Form;
